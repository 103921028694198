import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ProductList from './product_list.vue';
import CustomerList from './customer_list.vue';

formCreate.component('ProductList', ProductList);
formCreate.component('CustomerList', CustomerList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'terminal_stop_apply_form',
      formParentCode: 'CRM20211015000002744',
      province: '',
      city: '',
    };
  },
  methods: {
    // 重置规则
    setRule(v) {
      const item = v;
      if (item.field === 'customerName') { // 选择客户
        item.props = {
          ...item.props,
          params: {
            functionCode: 'customer_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: item.title,
        };
      }
      if (item.field === 'companyCode') {
        item.restful = '/mdm/companyInfo/companyPageList';
        item.restfulParams = { pageSize: 1000 };
        item.props = {
          ...item.props,
          remoteParams: 'companyName',
          remote: true,
          filterable: true,
        };
        item.optionsKey = {
          label: 'companyName',
          value: 'companyCode',
        };
        item.on = {
          change: (e) => {
            this.setValue({
              companyCode: e,
            });
          },
          getLabel: (e) => {
            this.setValue({
              companyName: e,
            });
          },
        };
      }
      if (item.field === 'province') {
        item.restful = '/mdm/mdmbankbranch/queryProvince';
        item.props = {
          ...item.props,
          remoteParams: 'province',
          remote: true,
          filterable: true,
        };
        item.optionsKey = {
          label: 'province',
          value: 'province',
        };
      }
      if (item.field === 'backAmount') { // 选择退款金额
        item.props = {
          ...item.props,
          min: 0,
          precision: 2,
        };
      }
      return item;
    },
    // 完成渲染
    formComplete() {
      this.disabled(true, ['city', 'backBankNo']);
      const userData = JSON.parse(localStorage.getItem('userInfo'));
      const province = this.getRule('province');
      const city = this.getRule('city');
      const backBankNo = this.getRule('backBankNo');
      province.on.change = (e) => {
        this.setValue({
          province: e,
          city: '',
          backBankName: '',
          backBankNo: '',
        });
        if (e) {
          city.restful = '/mdm/mdmbankbranch/queryCity';
          city.restfulParams = { province: e };
          city.props = {
            ...city.props,
            remoteParams: 'city',
            remote: true,
            filterable: true,
          };
          city.optionsKey = {
            label: 'city',
            value: 'city',
          };
          this.disabled(false, ['city']);
        }
      };
      city.on.change = (e) => {
        this.city = e;
        this.setValue({
          city: e,
          backBankName: '',
          backBankNo: '',
        });
        if (e) {
          backBankNo.restful = '/mdm/mdmbankbranch/queryBankBranchName';
          backBankNo.restfulParams = { province: province.value, city: e, selectedCode: '' };
          backBankNo.props = {
            ...backBankNo.props,
            remoteParams: 'bankBranchName',
            remote: true,
            filterable: true,
          };
          backBankNo.optionsKey = {
            label: 'bankBranchName',
            value: 'bankBranchNo',
          };
          this.disabled(false, ['backBankNo']);
        }
      };
      backBankNo.on.getLabel = (e) => {
        console.log(e, 'e1');
        this.setValue({
          backBankName: e,
        });
      };
      // 客户名称
      const customerName = this.getRule('customerName');
      customerName.on.change = (e) => {
        if (e && e.length > 0) {
          if (e[0].mdmCustomerContactVos && e[0].mdmCustomerContactVos.length > 0) {
            this.setValue({
              contactName: e[0].mdmCustomerContactVos[0].contactName,
              contactPhone: e[0].mdmCustomerContactVos[0].contactPhone,
            });
          }
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
            principal: userData.fullName, // 负责人
            principalPhone: userData.userPhone, // 负责人电话
            customerType: e[0].customerType, // 客户类型
            customerTax: e[0].ext3, // 客户税号
            companyAddress: e[0].registeredAddress,
          });
        } else {
          this.setValue({
            customerName: null,
            customerCode: null,
            principal: null,
            principalPhone: null,
            customerType: null,
            customerTax: null,
            contactName: null,
            contactPhone: null,
            companyAddress: null,
          });
        }
      };
      if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
        request.get('/mdm/MdmCustomerTerminationController/queryDetail', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            backBankNo.restful = '/mdm/mdmbankbranch/queryBankBranchName';
            backBankNo.restfulParams = { province: res.result.province, city: res.result.city, selectedCode: '' };
            backBankNo.props = {
              ...backBankNo.props,
              remoteParams: 'bankBranchName',
              remote: true,
              filterable: true,
            };
            backBankNo.optionsKey = {
              label: 'bankBranchName',
              value: 'bankBranchNo',
            };
            this.setValue({
              ...res.result,
              attach: res.result.attach || [],
              attachmentList: res.result.attachmentList || [],
            });
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        // 默认新增
        const url = '/mdm/MdmCustomerTerminationController/edit';
        // 有id  就是编辑
        if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
          formData.id = this.formConfig.row.id;
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
